import { notification } from "antd";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import { AdminService } from "./AdminService";

const MutRoute = ({ component: Component, ...rest }) => {
  const myRole = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")).role
    : "Guest";
  if (myRole !== "Mentor") {
    notification.destroy();
    notification.warn({
      message: "Unauthorized transaction!2",
      description: "Please login again",
    });
  }
  AdminService.getMeMut().then((data) => {
    console.log(data);
    if (data.data.role !== "Mut") {
      notification.destroy();
      notification.warn({
        message: "Unauthorized transaction1!",
        description: "Please login again",
      });
    }
  });
  return (
    <Route
      {...rest}
      render={({ location }) =>
        myRole === "Mentor" ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export { MutRoute };
