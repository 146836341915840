import React, { createContext, useEffect, useState } from "react";

const initialState = {};
export const GlobalContext = createContext(initialState);
export const GlobalContextProvider = ({ children }) => {
  const [initialRender, setInitialRender] = useState(true);
  const [logo, setLogo] = useState(null);
  const [user, setUser] = useState(null);
  const [cdata, setCdata] = useState(null);
  const [showAlerts, setShowAlerts] = useState(true);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      if (localStorage.getItem("user")) {
        setUser(JSON.parse(localStorage.getItem("user")));
      }
      if (localStorage.getItem("cdata")) {
        setLogo(
          process.env.REACT_APP_FILES_BASEURL +
            JSON.parse(localStorage.getItem("cdata")).logo
        );
        setCdata(JSON.parse(localStorage.getItem("cdata")));
        document.title = JSON.parse(localStorage.getItem("cdata")).name;
      } else {
        // AdminService.getMyCompany().then((data) => {
        //   console.log(data.data);
        //   localStorage.setItem("cdata", JSON.stringify(data.data));
        //   setCdata(data.data);
        //   setLogo(process.env.REACT_APP_FILES_BASEURL + data.data.logo);
        //   document.title = data.data.name;
        // });
      }
    }
  }, [cdata, initialRender]);
  return (
    <GlobalContext.Provider
      value={{ logo, cdata, user, setUser, showAlerts, setShowAlerts }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
