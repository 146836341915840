import {
  CalendarOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  ClusterOutlined,
  DashboardOutlined,
  FileImageOutlined,
  HeartOutlined,
  HomeOutlined,
  LoginOutlined,
  LogoutOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  NotificationOutlined,
  PieChartOutlined,
  QuestionOutlined,
  TeamOutlined,
  VerticalAlignMiddleOutlined,
} from "@ant-design/icons";
import { Button, Col, Layout, Menu, Row, Tag } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useContext, useEffect, useState } from "react";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import Cikislar from "src/components/admin/Cikislar";
import Contacts from "src/components/admin/Contacts";
import KeyfiyetRaporu from "src/components/admin/KeyfiyetRaporu";
import NewMentor from "src/components/admin/NewMentor";
import SetDay from "src/components/admin/SetDay";
import Sorular from "src/components/admin/Sorular";
import Subscriptions from "src/components/admin/Subscriptions";
import Tefsirciler from "src/components/admin/Tefsirciler";
import SliderAdmin from "src/components/admin/banner/SliderAdmin";
import AcTypes from "src/components/admin/camp/Actypes";
import CampMembers from "src/components/admin/camp/CampMembers";
import Choices from "src/components/admin/camp/Choices";
import EventDetails from "src/components/admin/events/EventDetails";
import EventEdit from "src/components/admin/events/EventEdit";
import EventsAdmin from "src/components/admin/events/EventsAdmin";
import PriorityEvents from "src/components/admin/events/PriorityEvents";
import Registrations from "src/components/admin/events/Registrations";
import SmsEvents from "src/components/admin/events/SmsEvents";
import SmsNotifications from "src/components/admin/events/SmsNotifications";
import NewsAdmin from "src/components/admin/news/NewsAdmin";
import NewsDetails from "src/components/admin/news/NewsDetails";
import NewsEdit from "src/components/admin/news/NewsEdit";
import Priority from "src/components/admin/news/Priority";
import EditProject from "src/components/admin/projects/EditProject";
import ProjectAdminDetails from "src/components/admin/projects/ProjectAdminDetails";
import Projects from "src/components/admin/projects/Projects";
import AdminSonAltiAy from "src/components/mentor/AdminSonAltiAy";
import EnSonRaporu from "src/components/mentor/EnsonRaporu";
import SonAyRaporu from "src/components/mentor/SonAyRaporu";
import ToplamGrafigi from "src/components/mentor/ToplamGrafigi";
import { GlobalContext } from "src/context/GlobalContext";
import AdminPage from "../components/admin/AdminPage";

const AdminLayout = () => {
  const { Header, Content, Footer, Sider } = Layout;
  const { lg, sm } = useBreakpoint();
  const [isSider, setIsSider] = useState(false);
  const { logo } = useContext(GlobalContext);
  const location = useLocation();
  const router = useHistory();
  const { SubMenu } = Menu;
  useEffect(() => {
    setIsSider(lg ? false : true);
  }, [lg]);
  return (
    <Layout>
      <Header
        style={{
          height: "75px",
          lineHeight: "75px",
          position: lg ? "fixed" : "unset",
          zIndex: 1,
          width: "100%",
          backgroundColor: "white",
          borderBottom: "1px solid #ccc",
          boxShadow: "1px 1px 10px #ddd",
        }}
      >
        <Row justify={!sm ? "start" : "space-between"} align="middle">
          <Col>
            <Button
              icon={isSider ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              size="large"
              onClick={() => setIsSider(!isSider)}
            />
          </Col>
          <Col>
            <img
              onClick={() => router.push("/home/homepage")}
              src={require("../assets/images/logo.png").default}
              alt="logo"
              style={{
                maxWidth: "250px",
                marginLeft: !sm ? "20px" : "",
                cursor: "pointer",
              }}
            />
          </Col>
          {lg && (
            <Col>
              <Button
                onClick={() => {
                  localStorage.clear();
                  router.push("/");
                }}
                type="primary"
                icon={<LogoutOutlined />}
              >
                Log Out
              </Button>
            </Col>
          )}
        </Row>
      </Header>
      <Layout>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          trigger={null}
          collapsible
          collapsed={isSider}
          theme="light"
          className={!lg ? "mobil-sider" : "desktop-sider"}
        >
          <br />
          <Tag
            color="blue"
            style={{
              width: "calc(100% - 40px)",
              textAlign: "center",
              margin: "5px 20px 0 20px",
            }}
          >
            Admin Menu
          </Tag>
          <Menu
            className="amenu"
            onSelect={() => {
              !lg && setIsSider(true);
            }}
            theme="light"
            mode="inline"
            defaultSelectedKeys={[location.pathname.split("/").pop()]}
          >
            <Menu.Item key="adminpage" icon={<DashboardOutlined />}>
              <Link to="/admin/adminpage">Dashboard</Link>
            </Menu.Item>
            <Menu.Item key="Project" icon={<ClusterOutlined />}>
              <Link to="/admin/projects">Projects</Link>
            </Menu.Item>
            <Menu.Item key="news" icon={<NotificationOutlined />}>
              <Link to="/admin/news">News</Link>
            </Menu.Item>
            <Menu.Item
              key="newspriority"
              icon={<VerticalAlignMiddleOutlined />}
            >
              <Link to="/admin/news/priority">News Priority</Link>
            </Menu.Item>
            <Menu.Item key="events" icon={<CalendarOutlined />}>
              <Link to="/admin/events">Events</Link>
            </Menu.Item>
            <Menu.Item
              key="eventspriority"
              icon={<VerticalAlignMiddleOutlined />}
            >
              <Link to="/admin/events/priority">Events Priority</Link>
            </Menu.Item>
            <Menu.Item key="slider" icon={<FileImageOutlined />}>
              <Link to="/admin/slider">Slider</Link>
            </Menu.Item>
            <Menu.Item key="registrations" icon={<CheckCircleOutlined />}>
              <Link to="/admin/registrations">Registrations</Link>
            </Menu.Item>
            <Menu.Item key="subscriptions" icon={<LoginOutlined />}>
              <Link to="/admin/subscriptions">Subscriptions</Link>
            </Menu.Item>
            <Menu.Item key="contacts" icon={<MailOutlined />}>
              <Link to="/admin/contacts">Contacts</Link>
            </Menu.Item>
            <Menu.Item key="setday" icon={<ClockCircleOutlined />}>
              <Link to="/admin/setday">Set Daily Program</Link>
            </Menu.Item>
            <SubMenu
              key="submenu"
              icon={<MailOutlined />}
              title="SMS Notifications"
            >
              <Menu.Item key="sms">
                <Link to="/admin/sms">New SMS Event</Link>
              </Menu.Item>
              <Menu.Item key="smsevents">
                <Link to="/admin/smsevents">SMS Events</Link>
              </Menu.Item>
              <Menu.Item key="unsubscriptions">
                <Link to="/admin/unsubscriptions">SMS Unsubscriptions</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="submenu2" icon={<MailOutlined />} title="Performans">
              <Menu.Item key="mentor">
                <Link to="/admin/newmentor">New Mentor</Link>
              </Menu.Item>
              <Menu.Item key="sonay">
                <Link to="/admin/sonay">Son Ay Raporu</Link>
              </Menu.Item>
              <Menu.Item key="sonalti">
                <Link to="/admin/sonaltiay">Son 6 Ay Raporu</Link>
              </Menu.Item>
              <Menu.Item key="enson">
                <Link to="/admin/enson">Son ilgi Raporu</Link>
              </Menu.Item>
              <Menu.Item key="toplam">
                <Link to="/admin/toplam">Toplam Puanlar</Link>
              </Menu.Item>
              {/* <Menu.Item key="smsevents">
                <Link to="/admin/smsevents">SMS Events</Link>
              </Menu.Item>
              <Menu.Item key="unsubscriptions">
                <Link to="/admin/unsubscriptions">SMS Unsubscriptions</Link>
              </Menu.Item> */}
            </SubMenu>
            <SubMenu key="camp" icon={<HomeOutlined />} title="Family Camp">
              <Menu.Item key="campmembers">
                <Link to="/admin/campmembers">Members</Link>
              </Menu.Item>
              {/* <Menu.Item key="choices">
                <Link to="/admin/choices">Acc.Choices</Link>
              </Menu.Item>
              {/* <Menu.Item key="smsevents">
                <Link to="/admin/smsevents">SMS Events</Link>
              </Menu.Item>
              <Menu.Item key="unsubscriptions">
                <Link to="/admin/unsubscriptions">SMS Unsubscriptions</Link>
              </Menu.Item>  */}
            </SubMenu>
            <Menu.Item key="tefsir" icon={<TeamOutlined />}>
              <Link to="/admin/tefsir">Tefsir Katilim</Link>
            </Menu.Item>
            <Menu.Item key="keyfiyetraporu" icon={<PieChartOutlined />}>
              <Link to="/admin/keyfiyetraporu">Keyfiyet Raporu</Link>
            </Menu.Item>
            <Menu.Item key="sorular" icon={<QuestionOutlined />}>
              <Link to="/admin/sorular">Sorular</Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Content
          style={{
            marginTop: lg ? "75px" : undefined,
            padding: "10px",
            borderLeft: "1px solid #ddd",
            minHeight: "calc(100vh - 150px)",
          }}
        >
          <Switch>
            <Route path="/admin" exact>
              <Redirect to="/admin/adminpage" />
            </Route>
            <Route path="/admin/adminpage" component={AdminPage} />
            <Route path="/admin/contacts" component={Contacts} />
            <Route path="/admin/unsubscriptions" component={Cikislar} />

            <Route path="/admin/setday" component={SetDay} />
            <Route
              path="/admin/projects/details"
              component={ProjectAdminDetails}
            />
            <Route path="/admin/projects/edit" component={EditProject} />

            <Route path="/admin/projects" component={Projects} />
            <Route path="/admin/news/:id/details" component={NewsDetails} />
            <Route path="/admin/news/:id/edit" component={NewsEdit} />
            <Route path="/admin/news/priority" component={Priority} />
            <Route path="/admin/news" component={NewsAdmin} />
            <Route path="/admin/actype" component={AcTypes} />
            <Route path="/admin/campmembers" component={CampMembers} />
            <Route path="/admin/choices" component={Choices} />
            <Route path="/admin/keyfiyetraporu" component={KeyfiyetRaporu} />

            <Route path="/admin/events/:id/details" component={EventDetails} />
            <Route path="/admin/events/:id/edit" component={EventEdit} />
            <Route path="/admin/events/priority" component={PriorityEvents} />
            <Route path="/admin/events" component={EventsAdmin} />
            <Route path="/admin/contacts" component={Contacts} />
            <Route path="/admin/registrations" component={Registrations} />
            <Route path="/admin/subscriptions" component={Subscriptions} />
            <Route path="/admin/slider" component={SliderAdmin} />
            <Route path="/admin/sms" component={SmsNotifications} />
            <Route path="/admin/smsevents" component={SmsEvents} />
            <Route path="/admin/newmentor" component={NewMentor} />
            <Route path="/admin/sonaltiay" component={AdminSonAltiAy} />
            <Route path="/admin/sonay" component={SonAyRaporu} />
            <Route path="/admin/tefsir" component={Tefsirciler} />

            <Route path="/admin/enson" component={EnSonRaporu} />
            <Route path="/admin/toplam" component={ToplamGrafigi} />
            <Route path="/admin/sorular" component={Sorular} />

            {/* <Route path="/staff/contacts" component={Contacts} />
            <Route path="/staff/newsletters" component={Newsletters} />
            <Route path="/staff/forms" component={Forms} />
           */}
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </Content>
      </Layout>
      <Footer className="footer">
        <div>
          <p className="text-small text-gray-400 text-center">
            <span className="text-emerald-600 font-semibold">
              &copy; Galaxy Foundation
            </span>
            {", "}2021 . All rights reserved. <br />
            Developed by{" "}
            <Link
              to={{ pathname: "https://softmile.com.au" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Softmile &nbsp;
            </Link>
            with <HeartOutlined /> React
          </p>
        </div>
      </Footer>
    </Layout>
  );
};

export default AdminLayout;
