import {
  HeartOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { Button, Col, Layout, Menu, Row, Tag } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useContext, useEffect, useState } from "react";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import EnquireArchive from "src/components/mentor/EnquireArchive";
import MutEnquireHome from "src/components/mentor/MutEnquireHome";
import { GlobalContext } from "src/context/GlobalContext";

const TopluLayout = () => {
  const { Header, Content, Footer, Sider } = Layout;
  const { lg, sm } = useBreakpoint();
  const [isSider, setIsSider] = useState(false);
  const { logo } = useContext(GlobalContext);
  const location = useLocation();
  const router = useHistory();
  useEffect(() => {
    setIsSider(lg ? false : true);
  }, [lg]);
  return (
    <Layout>
      <Header
        style={{
          height: "75px",
          lineHeight: "75px",
          position: lg ? "fixed" : "unset",
          zIndex: 1,
          width: "100%",
          backgroundColor: "white",
          borderBottom: "1px solid #ccc",
          boxShadow: "1px 1px 10px #ddd",
        }}
      >
        <Row justify={!sm ? "start" : "space-between"} align="middle">
          <Col>
            <Button
              icon={isSider ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              size="large"
              onClick={() => setIsSider(!isSider)}
            />
          </Col>
          <Col>
            <img
              onClick={() => router.push("/home/homepage")}
              src={require("../assets/images/logo.png").default}
              alt="logo"
              style={{
                maxWidth: "250px",
                marginLeft: !sm ? "20px" : "",
                cursor: "pointer",
              }}
            />
          </Col>
          {lg && (
            <Col>
              <Button
                onClick={() => {
                  localStorage.clear();
                  router.push("/");
                }}
                type="primary"
                icon={<LogoutOutlined />}
              >
                Log Out
              </Button>
            </Col>
          )}
        </Row>
      </Header>
      <Layout>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          trigger={null}
          collapsible
          collapsed={isSider}
          theme="light"
          className={!lg ? "mobil-sider" : "desktop-sider"}
        >
          <br />
          <Tag
            color="blue"
            style={{
              width: "calc(100% - 40px)",
              textAlign: "center",
              margin: "5px 20px 0 20px",
            }}
          >
            Enquire Menu
          </Tag>
          <Menu
            className="amenu"
            onSelect={() => {
              !lg && setIsSider(true);
            }}
            theme="light"
            mode="inline"
            defaultSelectedKeys={[location.pathname.split("/").pop()]}
          >
            {/* <Menu.Item key="adminpage" icon={<DashboardOutlined />}>
              <Link to="/mentor/home">Dashboard</Link>
            </Menu.Item> */}
            <Menu.Item key="home" icon={<UserAddOutlined />}>
              <Link to="/mutenquire/home">Sorular</Link>
            </Menu.Item>
            {/* <Menu.Item key="archive" icon={<FileZipOutlined />}>
              <Link to="/mutenquire/archive">Arsiv</Link>
            </Menu.Item> */}
          </Menu>
        </Sider>
        <Content
          style={{
            marginTop: lg ? "75px" : undefined,
            padding: "10px",
            borderLeft: "1px solid #ddd",
            minHeight: "calc(100vh - 150px)",
          }}
        >
          <Switch>
            <Route path="/mutenquire" exact>
              <Redirect to="/mutenquire/home" />
            </Route>
            <Route path="/mutenquire/home" component={MutEnquireHome} />
            <Route path="/mutenquire/archive" component={EnquireArchive} />

            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </Content>
      </Layout>
      <Footer className="footer">
        <div>
          <p className="text-small text-gray-400 text-center">
            <span className="text-emerald-600 font-semibold">
              &copy; Galaxy Foundation
            </span>
            {", "}2021 . All rights reserved. <br />
            Developed by{" "}
            <Link
              to={{ pathname: "https://softmile.com.au" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Softmile &nbsp;
            </Link>
            with <HeartOutlined /> React
          </p>
        </div>
      </Footer>
    </Layout>
  );
};

export default TopluLayout;
