import { notification } from "antd";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import { AdminService } from "./AdminService";

const MentorRoute = ({ component: Component, ...rest }) => {
  const myRole = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")).role
    : "Guest";
  if (myRole !== "Mentor") {
    notification.destroy();
    notification.warn({
      message: "Unauthorized transaction!",
      description: "Please login again",
    });
  }
  AdminService.getMeMentor().then((data) => {
    console.log(data);
  });
  return (
    <Route
      {...rest}
      render={({ location }) =>
        myRole === "Mentor" ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export { MentorRoute };
